import styled from 'styled-components';
import { queries } from '../../utils/mediaQueries';

export const Container = styled.div``;
export const ListContainer = styled.div`
  & > div:last-child {
    margin-bottom: 0;
  }
`;

export const ListItem = styled.div`
  width: 100%;
  padding: 15px 15px 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ccd2e3;
  position: relative;
  min-height: 60px;
  &.active {
    background: #f8eaec 0% 0% no-repeat padding-box;
    border: 1px solid #cd626e;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.top {
    padding-top: 5px;
  }
`;
export const Left = styled.div`
  position: relative;
  &.bold {
    font-weight: bold;
  }
  &.title {
    padding-left: 25px;
    font-size: 18px;
    & .discount {
      color: #bc2e3e;
    }
    @media ${queries.md} {
      font-size: 16px;
      max-width: 65%;
    }
    @media (max-width: 320px) {
      font-size: 14px;
      max-width: 65%;
    }
  }
  &.subtitle {
    padding-left: 25px;
    margin-top: 3px;
    font-size: 14px;
    @media ${queries.md} {
      max-width: 65%;
    }
    @media (max-width: 320px) {
      font-size: 12px;
    }
  }
`;
export const Right = styled.div`
  &.bold {
    font-weight: bold;
    font-size: 18px;
    @media ${queries.md} {
      font-size: 16px;
    }
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }
  &.price-per-day {
    color: #bc2e3e;
  }
  @media ${queries.md} {
    font-size: 14px;
  }
  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

export const InputRadio = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #ccd2e3;
  display: block;
  border-radius: 100%;
  position: absolute;
  top: 20px;
  left: 10px;

  &.active {
    border: 1px solid #bc2e3e;
    &:before {
      content: ' ';
      width: 12px;
      height: 12px;
      display: block;
      background-color: #bc2e3e;
      border-radius: 100px;
      margin: 3px;
    }
  }
`;
